<template>
  <div>
    
    <v-card class="text-xs-center">
      <v-card-title> Catraca </v-card-title>
      
      <v-layout justify-center>
        <v-card-actions>
          <div v-if="!showActions">{{message}}</div>
          <v-btn v-else x-large :loading="loading" color="green" @click="liberar">liberar</v-btn>
        </v-card-actions>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    showActions: false,
    loading: false,
    message: 'Verificando disponibilidade da catraca ...'
  }),
  methods: {
    timeout(time = 1000){
      return new Promise(resolve =>setTimeout(resolve, time))
    },
    async liberar() {
      this.loading = true;
      window.socket.send(JSON.stringify({to: '$2a$10$RKd5SaQL2BgqeAIkCvag6uPGoqC9p8TJ9fW9WRJ2KKeVAWRGV6GkO', message: 'open'}))
      await this.timeout(5000)
      this.loading = false;
      //window.socket.send('close')
      // this.loading = true;
      // await axios.get('https://esp8266.local/open').catch(() => this.message = 'Catraca indisponível, verifique se ela está conectada no wifi')
      // setTimeout(async () => {
      //   await axios.get('https://esp8266.local/close').catch(() => this.message = 'Catraca indisponível, verifique se ela está conectada no wifi')
      //   this.loading = false
      // }, 3000)
    },
  },

  mounted() {
    window.socket || this.$store.dispatch('socketConnection')
    this.showActions = true
    //axios.get('https://esp8266.local/close').then(()=>this.showActions = true).catch(() => this.message = 'Catraca indisponível, verifique se ela está conectada no wifi')
  },
};
</script>

<style lang="scss" scoped>
</style>